































































@import '../assets/scss/util';

.button-location {
  --padding-x: 0;
  --padding-y: 0;
  --background-color: var(--color-black);
  --hover-background-color: var(--grey-darkest);
  --color: var(--color-white);

  .button-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(32);
    height: rem(32);
  }

  .loader {
    --light-color: var(--tint-mid);
    --color: var(--color-tint);
  }

  .loader-spinner {
    margin: auto;
  }
}
