// Utilities ———————————————————————————————————————————————————————————

@function max($numbers...) {
  @return m#{a}x(#{$numbers});
}

@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}

@function rem($value: 16) {
  @return #{'calc((1rem/16) * '} $value #{')'};
}
@function -rem($value: 16) {
  @return #{'calc((-1rem/16) * '} $value #{')'};
}

@function em($value: 16, $context: 16) {
  @return #{'calc((1em /'} $context #{') * '} $value #{')'};
}

@mixin font-size($size: 16, $height: 24, $context: 16) {
  font-size: em($size, $context);
  line-height: ($height / $size);
}

@mixin padding-x($right: 0, $left: $right) {
  padding-left: $left;
  padding-right: $right;
}

@mixin padding-y($top: 0, $bottom: $top) {
  padding-top: $top;
  padding-bottom: $bottom;
}

@mixin margin-x($right: 0, $left: $right) {
  margin-left: $left;
  margin-right: $right;
}

@mixin margin-y($top: 0, $bottom: $top) {
  margin-top: $top;
  margin-bottom: $bottom;
}

@mixin focus-outline($color: var(--color-focus), $width: 3px, $offset: 2px) {
  outline: 0 solid transparent;
  outline-offset: $offset;

  &,
  &:focus {
    box-shadow: none;
  }

  &:focus {
    outline-width: $width;
    outline-color: $color;
  }
}

@mixin focus-shadow($color: var(--color-focus), $width: 3px) {
  box-shadow: 0 0 0 $width transparent;

  &,
  &:focus {
    outline: none;
  }

  &:focus {
    box-shadow: 0 0 0 $width $color;
  }
}

@mixin focus-shadow-inset($color: var(--color-focus), $width: 3px) {
  box-shadow: inset 0 0 0 $width transparent;

  &,
  &:focus {
    outline: none;
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 $width $color;
  }
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

html.is-app ._isnt-app,
html.is-ios ._isnt-ios,
html:not(.is-app) ._is-app,
html:not(.is-ios) ._is-ios {
  display: none;
}
