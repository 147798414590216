// Buttons —————————————————————————————————————————————————————————————

:root {
  --button-padding-x: 18;
  --button-padding-y: 10;

  --button-color: var(--color-text);
  --button-background-color: var(--contrast-lighter);
  --button-hover-background-color: var(--contrast-light);
  --button-focus-color: var(--contrast-mid);

  --button-key-color: var(--color-white);
  --button-key-background-color: var(--color-key);
  --button-key-hover-background-color: var(--color-key-darker);
  --button-key-focus-color: var(--color-focus);

  --button-light-key-color: var(--color-key);
  --button-light-key-background-color: var(--color-key-10);
  --button-light-key-hover-background-color: var(--color-key-20);
  --button-light-key-focus-color: var(--color-focus);

  --button-good-color: var(--color-text-good);
  --button-good-background-color: var(--color-good-20);
  --button-good-hover-background-color: var(--color-good-30);
  --button-good-focus-color: var(--color-good-10);

  --button-bad-color: var(--color-text-bad);
  --button-bad-background-color: var(--color-bad-20);
  --button-bad-hover-background-color: var(--color-bad-30);
  --button-bad-focus-color: var(--color-bad-10);
}

%button {
  --color: var(--button-color);
  --background-color: var(--button-background-color);
  --hover-background-color: var(--button-hover-background-color);
  --focus-color: var(--button-focus-color);
  --padding-x: var(--button-padding-x);
  --padding-y: var(--button-padding-y);
  --radius: var(--controls-radius);

  appearance: none;
  color: var(--color);
  background-color: var(--background-color);
  border: 1px solid transparent;
  border-radius: var(--radius);
  cursor: pointer;
  display: inline-block;
  line-height: (20/16);
  padding: rem(var(--padding-y)) rem(var(--padding-x));
  transition: border-color 0.1s, background-color 0.1s, box-shadow 0.1s;

  &[aria-pressed='true'],
  &:focus,
  &:hover {
    color: var(--color);
    outline: none;
    text-decoration: none;
    background-color: var(--hover-background-color);
  }

  &[disabled] {
    pointer-events: none;
    opacity: (4/5);

    > * {
      opacity: (3/5);
    }
  }

  @include focus-shadow(var(--focus-color));
}

%button._inner {
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding: 0;
  line-height: 1;
  -webkit-tap-highlight-color: transparent;

  .button-inner {
    background-color: var(--background-color);
    border: 1px solid transparent;
    border-radius: var(--radius);
    line-height: (20/16);
    padding: rem(var(--padding-y)) rem(var(--padding-x));
    transition: inherit;
  }

  &[aria-pressed='true'],
  &:focus,
  &:hover {
    background-color: transparent;

    .button-inner {
      background-color: var(--hover-background-color);
    }
  }

  &:focus .button-inner {
    box-shadow: 0 0 0 3px var(--focus-color);
  }
}

%button._key,
%button[type='submit'] {
  --color: var(--button-key-color);
  --background-color: var(--button-key-background-color);
  --hover-background-color: var(--button-key-hover-background-color);
  --focus-color: var(--button-key-focus-color);
}

%button._good {
  --color: var(--button-bad-color);
  --background-color: var(--button-bad-background-color);
  --hover-background-color: var(--button-bad-hover-background-color);
  --focus-color: var(--button-bad-focus-color);
}

%button._bad {
  --color: var(--button-bad-color);
  --background-color: var(--button-bad-background-color);
  --hover-background-color: var(--button-bad-hover-background-color);
  --focus-color: var(--button-bad-focus-color);
}

button,
.button {
  @extend %button;
}
