







































































































































































































































@import '../assets/scss/util';

$sheet-breakpoint: 641px;
$sheet-max-viewport: 412;

:root {
  --sheet-top: 0;
  --sheet-mid: 50%;
  --sheet-bottom: 8rem;

  --sheet-offset-top: var(--sheet-top);
  --sheet-offset-bottom: calc(
    var(--sheet-bottom) + env(safe-area-inset-bottom)
  );

  --sheet-slide-duration: 0.25s;
}

.sheet {
  position: absolute;
  top: var(--sheet-offset-top);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  pointer-events: none;
  overflow: hidden;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  max-width: rem($sheet-max-viewport + 40);
  padding: 0;
  padding-left: env(safe-area-inset-left);
  padding-right: calc(env(safe-area-inset-left) / 2);

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &._interactive {
    pointer-events: auto;
    overflow-y: scroll;
  }

  &._scrolled {
    scroll-snap-type: none;
  }

  @media (min-width: $sheet-breakpoint) {
    max-width: rem(400);
  }
}

.sheet-content {
  max-width: rem($sheet-max-viewport);
  margin: auto;
  position: relative;
  pointer-events: auto;

  scroll-snap-align: start;
  scroll-snap-stop: always;

  @media (min-width: $sheet-breakpoint) {
    max-width: rem(360);
  }
}

.sheet-margin {
  position: relative;
  width: 100%;
  background: transparent;
  height: calc(100% - var(--sheet-offset-bottom));
  will-change: height;

  ._bottom & {
    transition: height 0.4s ease-out;
  }
}

.sheet-stop {
  display: block;
  position: absolute;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 1px;
  // background-color: #f0f;

  &._top {
    top: calc(100% - var(--sheet-offset-bottom));
  }

  &._mid {
    $mid: (66.666% / (144 / 89));
    top: calc(#{$mid} - var(--sheet-offset-bottom));
    scroll-snap-align: start;
    scroll-snap-stop: always;

    @media (max-height: 32em) {
      top: calc(100% - 5.5rem - var(--sheet-offset-bottom));
    }
  }

  &._bottom {
    top: 0;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
}

.scroll-margin {
  position: absolute;
  left: 0;
  top: 100%;
  width: 1px;
}

// Animation

.sheet-enter,
.sheet-leave-to {
  opacity: 0;

  .sheet {
    transform: translateY(100vh);
  }
}

.sheet-enter-active {
  scroll-behavior: auto;
  transition: opacity 0.1s, visibility var(--sheet-slide-duration);

  .sheet {
    transition: transform var(--sheet-slide-duration)
      cubic-bezier((1/4), (10/12), (1/10), 1);
  }
}

.sheet-leave-active {
  transition: opacity 0.1s 0.2s, visibility var(--sheet-slide-duration);

  .sheet {
    transition: transform var(--sheet-slide-duration)
      cubic-bezier((1/4), (10/12), (1/10), 1);
  }
}
