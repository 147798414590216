


























































































































































































































































































.castle-map {
  &,
  #map {
    overflow: hidden;
    height: 100%;
  }

  &._hover canvas {
    cursor: pointer;
  }

  canvas {
    opacity: 0;
    transition: opacity 0.4s;
  }

  &._ready canvas {
    opacity: 1;
  }

  .loader {
    --size: 2em;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
  }
}
