// Card ————————————————————————————————————————————————————————————————

.card {
  position: relative;
  background-color: var(--color-root);
  min-height: 100vh;
  box-shadow: 0 0 12px var(--shade-light), 0 0 0 1px var(--shade-light);
  border-radius: 12px 12px 0 0;

  ._top & {
    border-radius: 0;
  }

  .layout {
    padding: 0;
  }
}

.card-header {
  position: sticky;
  top: 0;
  z-index: 9;
  background: inherit;
  border-radius: inherit;
  box-shadow: 0 1px var(--shade-lightest);
  transition: border-radius 0.2s, box-shadow 0.2s;
  overflow: hidden;

  &:before {
    $width: 36px;
    content: '';
    border-radius: 2px;
    height: 4px;
    width: $width;
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    background-color: currentColor;
    opacity: 0.1;
    transition: top 0.2s, opacity 0.2s;
  }

  .layout {
    display: flex;
    width: auto;
    min-height: rem(64);
    padding: 0 rem(var(--screen-padding-x));
  }

  .heading {
    flex: auto;
    margin: rem(16) 0;

    span {
      display: inline-block;
      margin-top: rem(8);
    }
  }

  ._interactive & {
    &:before {
      opacity: 0.4;
    }
  }

  ._top & {
    &:before {
      top: -6px;
      opacity: 0;
    }
  }

  ._scrolled & {
    box-shadow: 0 1px var(--shade-lightest), 0 2px 8px var(--shade-lightest);
  }

  h1 {
    @include font-size(26, 32);
  }

  h2 {
    @include font-size(18, 20);
  }

  h1 + h2 {
    margin-top: rem(8);
  }
}

.card-header-close {
  $size: 28;
  $margin: 18;
  display: flex;
  align-items: center;
  flex: none;
  font-size: rem($size);
  text-align: right;
  padding: 0 rem($margin);
  max-height: rem($size + ($margin * 2));
  margin-right: -rem(var(--screen-padding-x));

  &:focus {
    outline: none;
    .icon-close {
      box-shadow: 0 0 0 3px var(--contrast-light);
    }
  }
}

.card-content {
  padding-bottom: rem(128);
  transition: opacity 0.3s;

  ._bottom & {
    opacity: 0;
  }
}

.card footer {
  @include font-size(13, 16);
  box-shadow: 0 -1px var(--shade-lightest);
  padding: var(--space-rem-small) 0;

  p {
    margin: var(--space-rem-small) 0;
  }
}

.card .loader {
  color: var(--contrast-dark);
  justify-content: center;
  padding: var(--space-rem-biggest) 0;
}

// Transitions

.slide-up-enter-active {
  transition: transform 0.5s cubic-bezier((1/4), (10/12), (1/10), 1);
}

.slide-down-leave-active {
  transition: transform 0.5s ease-in;
}
.slide-up-leave-active,
.slide-down-enter-active {
  &:after {
    content: '';
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: var(--shade-light);
    border-radius: inherit;

    @media (prefers-color-scheme: dark) {
      background-color: var(--shade-dark);
    }
  }
}

.slide-up-leave-active,
.slide-down-leave-active {
  position: absolute;
  top: 0;
  width: 100%;
}

.slide-up-leave-active {
  transition: visibility 0.5s, box-shadow 0.5s;
  z-index: -1;
  box-shadow: none;

  &:after {
    opacity: 0;
    transition: opacity 0.3s ease-in 0.2s;
  }
}

.slide-up-enter,
.slide-down-leave-to {
  transform: translateY(100vh);
}

.slide-up-leave-to {
  &:after {
    opacity: 1;
  }
}

.slide-down-leave-active {
  z-index: 10;
  box-shadow: none;
}

.slide-down-enter-active {
  transition: visibility 0.5s;

  &:after {
    opacity: 1;
    transition: opacity 0.3s ease-out;
    z-index: 9;
  }
}

.slide-down-enter-to {
  &:after {
    opacity: 0;
  }
}
