// Colour ——————————————————————————————————————————————————————————————

$color-white: hsl(60, 2%, 92%);
$color-black: hsl(160, 2%, 28%);
$color-red: hsl(353, 90%, 50%);
$color-green: hsl(120, 60%, 50%);
$color-blue: hsl(204, 50%, 55%);
$color-yellow: hsl(50, 100%, 60%);

$color-key: $color-blue;
$color-key-lighter: mix($color-white, $color-key, 20);
$color-key-darker: mix($color-black, $color-key, 25);
$color-key-50: fade-out($color-key, 0.5);
$color-key-40: fade-out($color-key, 0.6);
$color-key-30: fade-out($color-key, 0.7);
$color-key-20: fade-out($color-key, 0.8);
$color-key-10: fade-out($color-key, 0.9);
$color-text-key: mix($color-black, $color-key, 20);

$color-good: $color-green;
$color-good-lighter: lighten($color-good, 12);
$color-good-darker: darken($color-good, 8);
$color-good-50: fade-out($color-good, 0.5);
$color-good-40: fade-out($color-good, 0.6);
$color-good-30: fade-out($color-good, 0.7);
$color-good-20: fade-out($color-good, 0.8);
$color-good-10: fade-out($color-good, 0.9);
$color-text-good: mix($color-black, $color-good, 30);

$color-bad: $color-red;
$color-bad-lighter: lighten($color-bad, 12);
$color-bad-darker: darken($color-bad, 6);
$color-bad-50: fade-out($color-bad, 0.5);
$color-bad-40: fade-out($color-bad, 0.6);
$color-bad-30: fade-out($color-bad, 0.7);
$color-bad-20: fade-out($color-bad, 0.8);
$color-bad-10: fade-out($color-bad, 0.9);
$color-text-bad: mix($color-black, $color-bad, 10);

$color-caution: $color-yellow;
$color-caution-lighter: lighten($color-caution, 12);
$color-caution-darker: darken($color-caution, 6);
$color-caution-50: fade-out($color-caution, 0.5);
$color-caution-40: fade-out($color-caution, 0.6);
$color-caution-30: fade-out($color-caution, 0.7);
$color-caution-20: fade-out($color-caution, 0.8);
$color-caution-10: fade-out($color-caution, 0.9);
$color-text-caution: darken($color-caution, 10);

$color-tint: $color-white;
$tint-darkest: fade-out($color-tint, 0.4);
$tint-darker: fade-out($color-tint, 0.5);
$tint-dark: fade-out($color-tint, 0.6);
$tint-mid: fade-out($color-tint, 0.7);
$tint-light: fade-out($color-tint, 0.8);
$tint-lighter: fade-out($color-tint, 0.88);
$tint-lightest: fade-out($color-tint, 0.94);

$color-shade: $color-black;
$shade-darkest: fade-out($color-shade, 0.2);
$shade-darker: fade-out($color-shade, 0.4);
$shade-dark: fade-out($color-shade, 0.5);
$shade-mid: fade-out($color-shade, 0.7);
$shade-light: fade-out($color-shade, 0.85);
$shade-lighter: fade-out($color-shade, 0.9);
$shade-lightest: fade-out($color-shade, 0.94);

$grey-darkest: mix($color-white, $color-black, 20);
$grey-darker: mix($color-white, $color-black, 40);
$grey-dark: mix($color-white, $color-black, 50);
$grey-mid: mix($color-white, $color-black, 70);
$grey-light: mix($color-white, $color-black, 85);
$grey-lighter: mix($color-white, $color-black, 90);
$grey-lightest: mix($color-white, $color-black, 94);

:root {
  --color-white: #{$color-white};
  --color-black: #{$color-black};
  --color-red: #{$color-red};
  --color-blue: #{$color-blue};
  --color-green: #{$color-green};

  --color-key: #{$color-key};
  --color-key-contrast: #{$color-white};
  --color-key-lighter: #{$color-key-lighter};
  --color-key-darker: #{$color-key-darker};
  --color-key-50: #{$color-key-50};
  --color-key-40: #{$color-key-40};
  --color-key-30: #{$color-key-30};
  --color-key-20: #{$color-key-20};
  --color-key-10: #{$color-key-10};
  --color-text-key: #{$color-text-key};

  --color-good: #{$color-good};
  --color-good-contrast: #{$color-white};
  --color-good-lighter: #{$color-good-lighter};
  --color-good-darker: #{$color-good-darker};
  --color-good-50: #{$color-good-50};
  --color-good-40: #{$color-good-40};
  --color-good-30: #{$color-good-30};
  --color-good-20: #{$color-good-20};
  --color-good-10: #{$color-good-10};
  --color-text-good: #{$color-text-good};

  --color-bad: #{$color-bad};
  --color-bad-contrast: #{$color-white};
  --color-bad-lighter: #{$color-bad-lighter};
  --color-bad-darker: #{$color-bad-darker};
  --color-bad-50: #{$color-bad-50};
  --color-bad-40: #{$color-bad-40};
  --color-bad-30: #{$color-bad-30};
  --color-bad-20: #{$color-bad-20};
  --color-bad-10: #{$color-bad-10};
  --color-text-bad: #{$color-text-bad};

  --color-caution: #{$color-caution};
  --color-caution-contrast: #{$color-black};
  --color-caution-lighter: #{$color-caution-lighter};
  --color-caution-darker: #{$color-caution-darker};
  --color-caution-50: #{$color-caution-50};
  --color-caution-40: #{$color-caution-40};
  --color-caution-30: #{$color-caution-30};
  --color-caution-20: #{$color-caution-20};
  --color-caution-10: #{$color-caution-10};
  --color-text-caution: #{$color-text-caution};

  --color-root: var(--color-white);
  --color-root-backdrop: #{$tint-darkest};

  --color-shade: #{$color-black};
  --color-tint: #{$color-white};
  --color-contrast: var(--color-black);

  --shade-darkest: #{$shade-darkest};
  --shade-darker: #{$shade-darker};
  --shade-dark: #{$shade-dark};
  --shade-mid: #{$shade-mid};
  --shade-light: #{$shade-light};
  --shade-lighter: #{$shade-lighter};
  --shade-lightest: #{$shade-lightest};

  --tint-darkest: #{$tint-darkest};
  --tint-darker: #{$tint-darker};
  --tint-dark: #{$tint-dark};
  --tint-mid: #{$tint-mid};
  --tint-light: #{$tint-light};
  --tint-lighter: #{$tint-lighter};
  --tint-lightest: #{$tint-lightest};

  --contrast-darkest: #{$shade-darkest};
  --contrast-darker: #{$shade-darker};
  --contrast-dark: #{$shade-dark};
  --contrast-mid: #{$shade-mid};
  --contrast-light: #{$shade-light};
  --contrast-lighter: #{$shade-lighter};
  --contrast-lightest: #{$shade-lightest};

  --grey-darkest: #{$grey-darkest};
  --grey-darker: #{$grey-darker};
  --grey-dark: #{$grey-dark};
  --grey-mid: #{$grey-mid};
  --grey-light: #{$grey-light};
  --grey-lighter: #{$grey-lighter};
  --grey-lightest: #{$grey-lightest};

  --color-text: var(--color-contrast);
  --text-light: var(--contrast-darker);
  --text-lighter: var(--contrast-mid);
  --text-lightest: var(--contrast-light);
  --color-focus: var(--color-key-40);

  --color-map-base: #f2eee8;
  --color-map-text: #464948;
}
