











































































@import '../assets/scss/util';

.castle-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.castle-list-item {
  a {
    display: block;

    &:focus,
    &:hover {
      background-color: var(--contrast-lightest);
    }
  }

  .layout {
    padding: rem(16) 0;
    box-shadow: 0 1px var(--contrast-lighter);
  }

  h2 {
    @include font-size(20, 20);
    padding-bottom: rem(8);
  }

  p {
    margin: 0;
  }
}
