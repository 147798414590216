// Map —————————————————————————————————————————————————————————————————

// Mapbox --------------------------------------------------------------------

.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-bottom-left {
  transition: bottom 0.15s;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  font-size: 12px;
  padding: 2px 5px 0;
  padding-right: max(env(safe-area-inset-right), 5px);

  .mapbox-improve-map {
    font-weight: 500;
  }
}

@media (min-width: 768px) {
  .mapboxgl-ctrl-bottom-left {
    left: rem(380);
  }
}

@media (max-width: (768px - 1)) {
  .mapboxgl-ctrl-bottom-left {
    bottom: var(--sheet-offset-bottom);
  }
}

@media (max-width: (380px + 60px)) {
  .mapboxgl-ctrl-bottom-right {
    bottom: var(--sheet-offset-bottom);
  }
}

// Elements ------------------------------------------------------------

#app > .logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  margin: rem(20);
  font-size: rem(48);
}

button.button-location {
  position: fixed;
  top: rem(12);
  right: rem(12);
  z-index: 1;
  padding: rem(12);
}

@supports (padding: max(1px)) {
  #app > .logo {
    margin-left: max(rem(20), env(safe-area-inset-left));
  }

  button.button-location {
    padding-right: max(rem(12), env(safe-area-inset-right));
  }
}

// Markers -------------------------------------------------------------

.marker {
  --size-rate: 0.875;
  --size-exp: calc(var(--map-zoom) * (var(--map-zoom) * var(--size-rate)));
  --size: calc(0.125px * var(--size-exp));
  --width: var(--size);
  --height: var(--size);
  --pin-background-color: var(--color-text);
  --pin-halo-color: var(--color-root);
  --pin-halo-width: 1px;
  --label-background-color: var(--pin-background-color);
  --label-color: var(--color-root);

  @supports (width: min(1px)) {
    --width: max(2px, min(var(--size), 20px));
    --height: max(2px, min(var(--size), 20px));
  }

  cursor: pointer;
  font-family: var(--font-body);
  padding: rem(10);

  &:hover {
    z-index: 2;
  }

  &[aria-selected='true'] {
    z-index: 1;
  }
}

.marker-head {
  width: var(--width);
  height: var(--height);
  background-color: var(--pin-background-color);
  box-shadow: 0 0 0 var(--pin-halo-width) var(--pin-halo-color);
  border-radius: 6em;
  transition: box-shadow 0.1s ease-out;

  .marker:hover &,
  .marker[aria-selected='true'] & {
    box-shadow: 0 0 0 var(--pin-halo-width) var(--pin-halo-color),
      0 0 0 calc(var(--pin-halo-width) + 2px) var(--pin-background-color);
  }
}

.marker-label {
  pointer-events: none;
  position: absolute;
  bottom: 50%;
  left: 100%;
  width: max-content;
  font-size: rem(16);

  color: var(--label-color);
  background-color: var(--label-background-color);

  opacity: calc(var(--map-zoom-floor) - 10);
  transition: opacity 0.1s ease-out;

  .marker:hover &,
  .marker[aria-selected='true'] & {
    opacity: 1;
  }
}

.marker-label-content {
  padding-top: rem(1);
  padding-left: rem(5);
  padding-right: rem(5);
}

// Context

.context-marker {
  --size-rate: 1.25;
  --width: var(--size);
  --height: var(--size);
  --pin-background-color: var(--color-key);
  --pin-halo-width: 2px;
  // --label-color: var(--color-text);

  @supports (width: min(1px)) {
    --width: max(12px, min(var(--size), 24px));
    --height: max(12px, min(var(--size), 24px));
  }

  padding: rem(6);
  z-index: 1;
}
