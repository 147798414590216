

















.logo {
  #lid {
    transition: 180ms;
  }

  &:hover #lid {
    transition: 80ms;
    d: path(
      'M291.667,1766.67l-25,0l0,-750l2133.33,0l0,750l-25,0c-237.634,379.38 -615.931,625 -1041.67,625c-425.735,0 -804.032,-245.62 -1041.67,-625Z'
    );
  }
}
