
















.loader {
  --size: 1.25em;
  --width: 2px;
  --light-color: var(--contrast-light);
  --color: var(--contrast-darker);
  --speed: #{(1s/2)};

  display: flex;
  align-items: center;
  color: currentColor;

  .loader-spinner,
  .loader-label {
    flex: none;
  }

  .loader-spinner {
    > span {
      display: block;
      width: var(--size);
      height: var(--size);
      border: var(--width) solid var(--light-color);
      border-top-color: var(--color) !important;
      border-radius: var(--size);
      animation: spin var(--speed) linear infinite;
    }
  }

  .loader-label:not(:empty) {
    margin-left: 0.5em;
  }

  @keyframes spin {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
}

[data-loading] {
  transition: opacity 0.2s;
  will-change: opacity;
}

[data-loading='true'] {
  opacity: (1/3);
}
